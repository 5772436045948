import {
	GET_LEADERS,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class Leaders {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
		this.loading = this.data.loading;
	}

	clear() {
		this.data.clear();
	}

	getLeaders() {
		const { method, endpoint } = GET_LEADERS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Leaders;
